



















































































































































































































































































import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import OrderService from '@/services/order'
import { SIDE_ORDER } from '@/services/order/models'
import { MESSAGE_STATUS } from '@/store/modules/message/types'
import CancelAllExchangeDialog from './CancelAllExchangeDialog.vue'
import { SelectOption } from '@/models/form'

export interface TableRecord {
  symbol: string
  side: string
  size: string
  price: string
  type: string
  options: string
  triggerPrice: string
  transactTime: string
  orderId: string
  status: string
  exchange?: string
  qty_base_ccy_init: string | number
  qty_quote_ccy_init: string | number
  qty_base_ccy_remaining: string | number
  qty_quote_ccy_remaining: string | number
  qty_base_ccy_executed: string | number
  qty_quote_ccy_executed: string | number
  leg: string
  product_type: string
  base_ccy: string
  quote_ccy: string
  position_type: string
  executed_qty: number
  text: string
  created_by: string
}

export default Vue.extend({
  components: {
    CancelAllExchangeDialog
  },

  props: {
    maxHeightTableContent: {
      type: Number,
      required: false
    },

    refresh: {
      type: Boolean,
      default: false
    },

    tableData: {
      type: Array as () => TableRecord[],
      required: true
    },

    loading: {
      type: Boolean,
      default: false
    },

    exchange: {
      type: String,
      required: true
    },
    allExchangeOption: {
      type: Array as () => SelectOption[],
      required: false
    }
  },

  data(): {
    isOpenCancelAllExchangeModal: boolean
    confirmModalOpen: boolean
    cancelOrderId: string
  } {
    return {
      isOpenCancelAllExchangeModal: false,
      confirmModalOpen: false,
      cancelOrderId: ''
    }
  },

  computed: {
    ...mapState('flagTrigger', ['orderFlag']),

    SIDES() {
      return SIDE_ORDER
    },

    countOfNewOrder(): number {
      return this.tableData.filter((item) => item.status.toUpperCase() === 'NEW').length
    }
  },

  methods: {
    ...mapMutations('message', ['SET_MESSAGE']),

    ...mapMutations('flagTrigger', ['TOGGLE_ORDER_TRIGGER']),

    async cancelAllOrderApi(exchange: string) {
      this.$emit('update:loading', true)

      try {
        // call cancel order
        await OrderService.cancelAllOrder({ exchange })

        // show notification
        this.SET_MESSAGE({
          type: MESSAGE_STATUS.SUCCESS,
          message: `${this.countOfNewOrder} order(s) canceled!`
        })

        this.$emit('cancel-all')
      } finally {
        this.$emit('update:loading', false)
        this.isOpenCancelAllExchangeModal = false
      }
    },

    onSubmitCancelAll(val: SelectOption) {
      this.cancelAllOrderApi(String(val.value))
    },

    cancelAll() {
      if (this.countOfNewOrder === 0) return

      // open dialog choose exchange to cancel all
      if (this.exchange === '') {
        this.isOpenCancelAllExchangeModal = true
        return
      }

      this.cancelAllOrderApi(this.exchange)
    },

    showConfirmCancelOrder(orderId: string) {
      this.confirmModalOpen = true
      this.cancelOrderId = orderId
    },

    closeConfirmCancelOrder() {
      this.confirmModalOpen = false
      this.cancelOrderId = ''
    },

    async cancelOneOrder() {
      if (!this.cancelOrderId) return

      this.$emit('update:loading', true)

      try {
        // get index
        const index = this.tableData.findIndex((item: TableRecord) => item.orderId === this.cancelOrderId)

        const dataReq = {
          exchange: String(this.tableData[index].exchange),
          id: this.cancelOrderId
        }

        // call cancel order
        await OrderService.cancelOrderExchange(dataReq)

        // show notification
        this.SET_MESSAGE({
          type: MESSAGE_STATUS.SUCCESS,
          message: 'Cancel order success!'
        })

        // set status
        this.tableData[index].status = 'Canceled'
      } finally {
        this.$emit('update:loading', false)
        this.closeConfirmCancelOrder()
      }
    },

    async editOneOrder(record: TableRecord) {
      this.$emit('on-open-edit-modal', record)
    },

    displayedText(text: string): string {
      if (!text) return ''
      return text.length > 6 ? `${text.slice(0, 5)}...` : text
    }
  }
})
